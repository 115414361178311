import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/clientes";

class ClienteService {

    getAll(params) {
        return axios.get(API_URL, {
            params: params,
            headers: authHeader()
        });
    }

    get(id) {
        return axios.get(API_URL + '/' + id, { headers: authHeader() });
    }

    saveOrUpdate(cliente) {
        if (!cliente.id) {
            return axios.post(API_URL,
                { nome: cliente.nome },
                { headers: authHeader() });
        }
        else {
            return axios.put(API_URL + '/' + cliente.id,
                { nome: cliente.nome },
                { headers: authHeader() });
        }
    }

    delete(id) {
        return axios.delete(API_URL + '/' + id, { headers: authHeader() });
    }
}

export default new ClienteService();
